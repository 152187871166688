import backArrow from "./img/backarrow.svg";
import sortByLogo from "./img/sortby.svg";
import sortByFilter from "./img/filter.svg";
import React from "react";
import CatalogNavigate from "./CatalogNavigate";
export default function HeaderCatalog(props){

    return(
        <div>
            <CatalogNavigate pageModifier="catalog-navigate__navigate-menu_catalog"/>
            <div className="header-catalog__top-menu">
                <div className="header-catalog__count">
                    <div className="header-catalog__sort-count">
                        <p className="header-catalog__sort-text">Порядок по умолчанию</p>
                        <img className="header-catalog__sort-icon" src={sortByLogo}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

