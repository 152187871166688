import { SmartCaptcha } from '@yandex/smart-captcha';
import React, {useEffect, useState} from "react";
import {api} from "./utils/Api";


export const ComponentWithCaptcha = (props) => {
    const [token, setToken] = useState('');
    const [ipAddress, setIPAddress] = useState('')

    useEffect(() => {
        api.sendCaptchaToken(token, ipAddress)
            .then((res) => {
                props.setCaptchaValidation(res)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token])


    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIPAddress(data.ip))
            .catch(error => console.log(error))
    }, []);

    return <SmartCaptcha  sitekey="ysc1_zmzFmGxXalFacinQESzERhiUoDti7kOIfhj1zlfi8a7e7376" onSuccess={setToken} />;
};