import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from "./Header"

import Slider from "./Slider";
import Footer from "./Footer";
import SiteSection from "./SiteSection";
import FeedbackForm from "./FeedbackForm";
import Sketh from "./Sketh";
import ArticleSliders from "./ArticleSliders";
import Advantages from "./Advantages";
import SaleSection from "./SaleSection";
import Helmet from "react-helmet";

export default function NotFound(){


    return(
        <>
            <Helmet>
                <title>Страница не найдена</title>
                <meta name="description" content="Страница не найдена. К сожалению, мы не смогли найти запрошенную страницу." />
                <meta name="robots" content="noindex" />
            </Helmet>
            <h2>404</h2>
            <p>Упс, кажется здесь ничего нет</p>
        </>

    )
}
