import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import Header from "./Header"
import img from "./img/Rectangle 38.png"
import img404 from "./img/1646540500_15-abrakadabra-fun-p-belii-fon-bolshoe-razreshenie-22.png"

import quantityArrow from "./img/arrow-quanity.png"
import plusIcon from "./img/plus.svg"

import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import HeaderCatalog from "./HeaderCatalog";
import FilterBlock from "./FilterBlock";
import {api} from "./utils/Api";
import Helmet from "react-helmet";
import CatalogNavigate from "./CatalogNavigate";

export default function ProductCard(props){
    const { productId } = useParams()
    const [catalog,setCatalog]=useState()




    function setCatalogDataFromApi(){
        api.getCatalog({ "filterDict":JSON.stringify({_id:productId}), "selectedFields": JSON.stringify({})  }).then((res) => { setCatalog(res[0])})

    }

    useEffect(()=>{
        setCatalogDataFromApi()
    },[productId])

    const[instructionState, setInstructionState] = useState("product-card__instruction-link")
    function clickInstruction(){
        if (instructionState==="product-card__instruction-link"){
            setInstructionState("product-card__instruction-link product-card__instruction-link_opened")
        }
        else {
            setInstructionState("product-card__instruction-link")
        }
    }



    return(

        <div className="product-card">
            <CatalogNavigate pageModifier="catalog-navigate__navigate-menu_product"/>
            {catalog ? (

                <>

                    <Helmet>
                        <title>
                            {`Купить онлайн ${catalog.Title}`}
                        </title>
                        <meta
                            name="description"
                            content={`В нашем интернет-магазине вы можете приобрести ${catalog.Title} и другое оборудование по выгодной цене. ${catalog.Description}`}
                        />
                        <meta
                            name="keywords"
                            content="Автополив, Ландшафтное освещение, Садовые системы полива, Смарт-технологии для сада, Садовая техника, Системы полива для участка, Ландшафтный дизайн, Садовые насосы, Экологичные решения для сада, Садовый инвентарь, Системы автоматического полива, Садовая техника и оборудование, Умные решения для сада, Садовые аксессуары, Энергосберегающие системы полива, Садовые приборы, Эстетика сада, Садовое оборудование, Эффективные решения для полива, Садовая автоматика, Садовая мебель, Садовые украшения, Газонные покрытия, Садовые лестницы, Декоративные элементы для сада, Садовые газоны, Садовые площадки, Озеленение участка, Водные фонтаны для сада, Садовые дорожки, Уход за растениями, Системы капельного полива, Садовые решетки, Садовые качели, Садовые беседки, Уличные горшки, Садовые камины, Дачные товары, Садовые строительные материалы, Садовые новинки, Садовый DIY, Садовые тенденции, Садовые акции, Садовые советы."
                        />
                    </Helmet>
            <div className="product-card__image-count">
                <img className="product-card__image" src={catalog.ImageLink} onError={(e)=>{e.target.src=img404}} />
                <div className="product-card__price-count product-card__price-count_mobile-view">
                    <p className="product-card__new-price">{Math.round(catalog.Price)} ₽</p>
                    <p className="product-card__old-price"></p>
                    <div className="product-card__sale-count">
                        <p className="product-card__sale-price">99 %</p>
                    </div>
                </div>

                <div className="product-card__quantity-count product-card__quantity-count_mobile-view">
                    <img className="product-card__quantity-arrow product-card__quantity-down" src={quantityArrow}/>
                    <div className="product-card__quantity-wrapper">
                        <p className="product-card__quantity-integer">1</p>
                    </div>
                    <img className="product-card__quantity-arrow product-card__quantity-up" src={quantityArrow}/>
                </div>
                <div className="product-card__order-button product-card__order-button_mobile-view">
                    <p className="product-card__order-button-text"> Добавить в корзину</p>
                </div>
            </div>

            <div className="product-card__description-count">
                <h3 className="product-card__title">{catalog.Title}</h3>
                <p className="product-card__description-text">{catalog.Description.replace(/\\n/g, '<br>\n')}</p>
                <div className="product-card__price-count product-card__price-count_pc-view">
                    <p className="product-card__new-price">{Math.round(catalog.Price)} ₽</p>
                    <p className="product-card__old-price"></p>
                    <div className="product-card__sale-count">
                        <p className="product-card__sale-price">99 %</p>
                    </div>
                </div>

                <div className="product-card__quantity-count product-card__quantity-count_pc-view">
                    <img className="product-card__quantity-arrow product-card__quantity-down" src={quantityArrow}/>
                    <div className="product-card__quantity-wrapper">
                        <p className="product-card__quantity-integer">1</p>
                    </div>
                    <img className="product-card__quantity-arrow product-card__quantity-up" src={quantityArrow}/>
                </div>
                <div className="product-card__order-button product-card__order-button_pc-view">
                    <p className="product-card__order-button-text"> Добавить в корзину</p>
                </div>
                <div className="product-card__information-wrapper">
                    <div className="product-card__instruction-count">
                        <p className="product-card__instruction-text"> Инструкция</p>
                        <img onClick={clickInstruction} className="product-card__instruction-image" src={plusIcon}/>
                        <a className={instructionState} >Здесь должна быть ссылка</a>
                    </div>
                    <div className="product-card__instruction-count">
                        <p className="product-card__instruction-text"> Инструкция</p>
                        <img  className="product-card__instruction-image" src={plusIcon}/>
                        <a className="product-card__instruction-link product-card__instruction-link_opened" >Здесь должна быть ссылка</a>
                    </div>
                </div>
            </div>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>

    )
}
