import React, {useEffect} from "react";
import {BrowserRouter as Router, Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import HomePage from "./HomePage";

import CatalogSystemPoliv from "./CatalogSystemPoliv";
import ProductCard from "./ProductCard";
import BurgerMenu from "./BurgerMenu";
import Header from "./Header";
import Signin from "./Signin";
import Footer from "./Footer";
import Service from "./Service";
import Popup from "./Popup";
import Articles from "./Articles";
import FormValidator from "./FormValidator";
import {popupCallBack,  selectors} from "./constants/constants";
import NotFound from "./NotFound";
import Calculator from "./Calculator";


function Page() {

    const [burgerOpen, setBurgerOpen] = React.useState(false);
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [typePopup, setTypePopup] = React.useState({});
    const { pathname } = useLocation();


    // useEffect(() => {
    //     window.addEventListener('scroll', () => {
    //         document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    //     })
    // }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    function controlBurger() {
        setBurgerOpen(!burgerOpen);
    }
    function controlPopup() {
        setPopupOpen(!popupOpen);
    }

    const closeByOutsideClick = (evt) => {
        const eventTarget = evt.target;
        if(eventTarget.classList.contains('burger-menu_visible' )) {
            controlBurger()
        }
        if(eventTarget.classList.contains('popup_visible' )) {
            controlPopup()
        }
    }


    useEffect(() => {

        const body = document.querySelector('.view-window');

        if (burgerOpen || popupOpen) {
            document.addEventListener('mousedown', closeByOutsideClick);
            const scrollY = window.scrollY ;

            body.style.position = 'fixed';
            body.style.top = `-${scrollY}px`;

        } else {
            document.removeEventListener('mousedown', closeByOutsideClick);
            const scrollY = body.style.top ;

            body.style.position = '';
            body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || "0") * -1);
        }
        return () => {
            document.removeEventListener('mousedown', closeByOutsideClick);
        };
    }, [burgerOpen, popupOpen]);

    return (
        <>
            <BurgerMenu burgerOpen={burgerOpen} closeBurger={controlBurger}/>
            <Popup popupOpen={popupOpen} closePopup={controlPopup} typePopup={typePopup}/>
            <div className={`view-window ${popupOpen || burgerOpen ? "view-window_opened" : ""}`}>

                <div className="App">

                    <Routes>
                        <Route
                            path="/uslugi/*"
                            element={<Header noBackgroundImage={true} openBurger={controlBurger} openPopup={controlPopup} setTypePopup={setTypePopup}/>}
                        />
                        <Route
                            path="/*"
                            element={<Header noBackgroundImage={false} openBurger={controlBurger} openPopup={controlPopup} setTypePopup={setTypePopup}/>}
                        />
                    </Routes>

                    <Routes>

                        <Route
                            path="/"
                            element={<HomePage openPopup={controlPopup} setTypePopup={setTypePopup}/>}
                        />

                        <Route
                            path="/uslugi/:serviceName"
                            element={<Service openPopup={controlPopup} setTypePopup={setTypePopup}/>}
                        />


                        <Route
                            element={<CatalogSystemPoliv />}
                            path="/market/*" />
                        {/*<Route*/}
                        {/*    element={<Calculator />}*/}
                        {/*    path="/calculator" />*/}
                        <Route
                            element={<Articles />}
                            path="/article" />

                        <Route path="/*" element={<NotFound />} />

                    </Routes>
                    <Footer openPopup={controlPopup} setTypePopup={setTypePopup}/>
                </div>
            </div>
        </>
    );
}

export default Page;

