import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import close_icon from "./img/close.png"
import sale_image from "./img/landscape/audit-smeti-min.png";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { api } from "./utils/Api";
import {ComponentWithCaptcha} from "./ComponentWithCaptcha";
import {blue} from "@mui/material/colors";
import {PUBLIC_URL} from "./constants/constants";

export default function Popup(props) {
    const [number, setNumber] = useState("")
    const [captchaValidation, setCaptchaValidation] = useState(false)
    const [name, setName] = useState("")

    const [personalDate, setPersonalDate] = useState(false)
    const [validate, setValidate] = useState(false)

    const numberInputValidate = document.querySelector('.PhoneInputInput')
    const nameInputValidate = document.querySelector('.popup__input_name')

    const typePopup = props.typePopup
    function handleChangeNumber(e) {
        setNumber(e.target.value);
    }

    function handleChangeName(e) {
        setName(e.target.value);
    }
    function changePersonalDateState(){
        setPersonalDate(!personalDate)
    }

    function handleSubmit(e) {
        e.preventDefault()
        props.closePopup()
        api.createCallBack(name, number, typePopup.type, typePopup.title)
            .then(() => {
            })
            .catch((error) => {
                console.log(error);
            });
        setNumber("")
        setName("")
        setPersonalDate(false)

    }
    useEffect(() =>{
        if (personalDate ){
            if( number !== "" && name !== "" && number !== undefined && captchaValidation){
                if( numberInputValidate.validity.valid &&  nameInputValidate.validity.valid){
                    setValidate(true)
                }
                else {
                    setValidate(false)
                }
            }
            else {
                setValidate(false)
            }
        }
        else {
            setValidate(false)
        }
    }, [number,name, personalDate, captchaValidation])




    function DefaultPopup(){
        return(
            <p className="popup__title">
                Оставьте свои контактные данные и мы вам перезвоним!
            </p>
        )
    }

    function SalePopup(){
        return(
                <>
                    <img className="popup__sale-img" src={sale_image}/>
                    <p className="popup__title">
                        Оставьте заявку на разработку <b style={{color: "#00db6a"}}>проекта ландшафтного дизайна</b> и получите проект системы полива <b style={{color: "#05A2D6"}}>БЕСПЛАТНО</b>!
                    </p>
                </>
        )
    }

    function ServicePopup(){
        return(
                <>
                    <p className="popup__title">
                        {typePopup.title}
                    </p>
                    <img className="popup__service-img" src={PUBLIC_URL + typePopup.linkImage}/>
                    <p className="popup__description">
                        {typePopup.description}
                    </p>

                </>
        )
    }

    function ChangeRenderPopup(){
       if (typePopup.type === "default"){
           return <DefaultPopup />
       } else if(typePopup.type === "sale"){
           return <SalePopup />
       } else if(typePopup.type === "service"){
           return <ServicePopup />
       }
    }
    return (
        <section className={`popup ${props.popupOpen ? 'popup_visible' : ''}`}>
            <div className="popup__page">
                <img className="popup__close-button"
                     src={close_icon}
                     onClick={props.closePopup}/>

                <ChangeRenderPopup/>
                <form className={"popup__form"} onSubmit={handleSubmit}>

                    <div className={"popup__input-count"}>
                        <label className={"popup__label popup__label_name"}>
                            Имя
                        </label>
                        <input minLength="3"
                               maxLength="10"
                               type="text"
                               placeholder={"Введите ваше имя"}
                               className={"popup__input popup__input_name"}
                               value={name}
                               onChange={handleChangeName}
                        />
                    </div>

                    <div className={"popup__input-count"}>
                        <label className={"popup__label popup__label_number"}>
                            Телефон
                        </label>
                        <PhoneInput
                            minLength="9"
                            maxLength="17"
                            className={"popup__input popup__input_number"}
                            placeholder="(XXX) XXX-XXXX"
                            defaultCountry="RU"
                            value={number}
                            onChange={setNumber}
                        />
                    </div>
                    <div className={"popup__input-count-checkbox"}>

                        <input type="checkbox"
                               checked={personalDate}
                               onChange={changePersonalDateState}
                               className={"popup__input-checkbox"}/>
                        <label className={"popup__label-checkbox"}>
                            <a>Соглашение на обработку персональных данных</a>
                        </label>
                    </div>
                    <ComponentWithCaptcha captchaValidation={captchaValidation} setCaptchaValidation={setCaptchaValidation}/>
                    <button disabled={!validate}
                            className={`popup__button ${!validate ? "popup__button_disabled" : ""}`} type="submit" >
                        Отправить
                    </button>
                </form>
            </div>
        </section>


    );
}



