import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import Header from "./Header"

import Slider from "./Slider";
import Footer from "./Footer";
import SiteSection from "./SiteSection";
import FeedbackForm from "./FeedbackForm";
import Sketh from "./Sketh";
import creative_approach_ico from "./img/output-onlinepngtools.png";
import key_work_ico from "./img/keyworkicon.png";
import five_year_ico from "./img/5year.png";
import time_ico from "./img/time-ico.png";
import price_ico from "./img/price-icon.png";

import sales_image from "./img/landscape/shutterstock_1162697149_edited.jpg";

import {useParams} from "react-router-dom";
import SaleSection from "./SaleSection";
import MainServiceSection from "./MainServiceSection";
import SubServiceSection from "./SubServiceSection";
import {PUBLIC_URL} from "./constants/constants"
import Advantages from "./Advantages";
import Helmet from "react-helmet";

export default function Service(props){
    const servicesDict = {
        "landscape-light": {
            "img":{
                "link":"/landscape/tab-light (1)-min .png",
                "alt":"",
            },
            "title":"Ландшафтное освещение",
            "description": "Ландшафтное освещение - это не только безопасность и функционал, а ещё искусство преобразить ваш сад в удивительное пространство днем и ночью. Эстетика в сочетании с функциональностью, подчеркивая красоту растений и создавая уютные уголки. Современные технологии, такие как светодиоды, делают его энергоэффективным и долговечным. Возможность управления интенсивностью и цветом света добавляет вариативности и создает неповторимую атмосферу. Превратите ваш сад в магическое пространство с нашим ландшафтным освещением.",
            "subServices": [
                {
                    "name":  "Планирование и дизайн",
                    "description":"Мы внимательно изучаем ваши потребности и предпочтения, чтобы разработать уникальный план освещения, соответствующий вашему стилю и функциональным требованиям",
                    "imgLink":"/landscape/41c021b420c3ad2a3b31cfad8649d117.jpg",
                },
                {
                    "name": "Выбор светильников",
                    "description":"Наша компания предлагает широкий ассортимент высококачественных светильников различных стилей и типов, обеспечивая оптимальное сочетание эстетики и производительности",
                    "imgLink":"/landscape/lights-07.0cprqw..jpg",
                },
                {
                    "name":"Определение источников питания" ,
                    "description":"Мы поможем вам выбрать оптимальные источники питания, в том числе солнечные батареи или сеть переменного тока, с учетом энергоэффективности и экологических аспектов",
                    "imgLink":"/landscape/audit-smeti-min.png",
                },
                {
                    "name": "Подготовка места",
                    "description":"Наши специалисты тщательно готовят место для установки светильников, обеспечивая устойчивое и безопасное размещение.",
                    "imgLink":"/landscape/zakladnaya-pod-fonar-ulichnogo-osvescheniya.jpg",
                },
                {
                    "name":"Укладка кабелей" ,
                    "description":"Мы профессионально разводим кабели, гарантируя их надежность и безопасность. При этом мы уделяем особое внимание дизайну, чтобы кабели оставались невидимыми.",
                    "imgLink":"/landscape/mini-ekskavator-rabota-3.jpg",
                },
                {
                    "name": "Установка светильников",
                    "description":"Наши опытные специалисты аккуратно устанавливают светильники в соответствии с проектом, обеспечивая оптимальное направление света.",
                    "imgLink":"/landscape/1596430662132367872-min.jpg",
                },
                {
                    "name":"Подключение к источнику питания" ,
                    "description":"Мы профессионально подключаем светильники к выбранным источникам питания, гарантируя безотказную работу всей системы.",
                    "imgLink":"/landscape/Ha1876fc0a82d4687aa2dcdbb4cb09dcd2.jpeg",
                },
                {
                    "name":"Настройка и тестирование" ,
                    "description":"После установки мы осуществляем настройку и тестирование каждого светильника, чтобы обеспечить соответствие вашим ожиданиям.",
                    "imgLink":"/landscape/John-Cullen-Fife-Rd-14.1507136059.0785.jpg",
                },
            ],
            "subServicePopup":
                [
                    {
                    "name": "Ландшафтное освещение 'ПОД КЛЮЧ'",
                    "description": "Сделаем проект, поможем с выбором светильников, выполним монтаж и подключение к контроллеру",
                    "price": "от 50 000",
                    "unit": "за услугу",
                    "img": {
                        "link": "/landscape/osvechenie-sada6.jpg",
                        "alt": "",
                    }
                },
                    {
                        "name": "Разработка проекта",
                        "description": "Разработка индивидуального проектного решения с исполнением всех ваших пожеланий и наших идей",
                        "price": "от 10 000",
                        "unit": "за услугу",
                        "img": {
                            "link": "/landscape/landshaftnyj-dezajn.jpg",
                            "alt": "",
                        }
                    },
                    {
                        "name": "Новогоднее освещение",
                        "description": "Создадим атмосферу и настроение Нового Года даже если вам 35+",
                        "price": "от 30 000",
                        "unit": "за услугу",
                        "img": {
                            "link": "/landscape/photo-min.jpg",
                            "alt": "",
                        }
                    },
                    {
                        "name": "Умное освещение сада",
                        "description": "Для любителей технологичных решений мы подготовили специальный пакет, с возможностью выбора цвета и регулировки мощности свечения отдельных светильников прямо с телефона",
                        "price": "от 200 000",
                        "unit": "за услугу",
                        "img": {
                            "link": "/landscape/GardenDesignProject46-1.jpg",
                            "alt": "",
                        }
                    },

                ]
        },
        "landscape-design": {
            "img":{
                "link":"/landscape/1581099513_12-p-gabioni-v-landshaftnom-dizaine-38.png",
                "alt":"Прекрасное сочетание газона и мраморной обсыпки белого цвета. А невысокий забор в минималистичном стиле наполняет пространство свободой",

            },
            "title":"Ландшафтный дизайн 'Под ключ'",
            "description": "Ландшафтный дизайн под ключ – это полная трансформация вашего участка, начиная с концепции и заканчивая полным воплощением идей. Профессионалы берут на себя всю работу, от выбора растений и оформления до создания путей и установки систем полива. Этот комплексный подход позволяет превратить обычный участок в оазис красоты и комфорта, полностью соответствующий вашим вкусам и потребностям. В результате вы получаете не просто сад, а уникальное пространство, приносящее радость и удовлетворение.",
            "subServices": [
                {
                    "name": "Изучение потребностей",
                    "description":"Мы начинаем с тщательного изучения ваших пожеланий и особенностей участка для создания персонализированного проекта.",
                    "imgLink":"/landscape/Copy+of+KAN+JOB+POSTINGS+(2).jpg",
                },
                {
                    "name": "Создание концепции",
                    "description":"Мы разрабатываем уникальную концепцию дизайна, предлагая вам эскизы и макеты для визуализации будущего ландшафта.",
                    "imgLink":"/landscape/1672252609_na-dache-pro-p-landshaftnii-dizain-risunok-foto-22-min.jpg"
                },
                {
                    "name": "Выбор стилевого решения",
                    "description":"Наши дизайнеры помогут вам выбрать стилевое решение, которое отражает ваш вкус и гармонирует с окружающей архитектурой.",
                    "imgLink":"/landscape/tarlashi_011.jpg"
                },
                {
                    "name":"Разработка проекта" ,
                    "description":"Мы создаем детальный проект, включающий в себя расположение растений, элементы декора, пути и все необходимые детали.",
                    "imgLink":"/landscape/service2-min.jpg"
                },
                {
                    "name": "Планирование системы полива",
                    "description": "Интегрируем эффективную систему полива, чтобы ваш сад всегда выглядел свежим и здоровым.",
                    "imgLink":"/landscape/4fe3d5c21cfe84ca52dc43494114ace5.jpeg"
                },
                {
                    "name":  "Подготовка участка",
                    "description":"Мы осуществляем подготовку участка, удаляя ненужные элементы и обеспечивая оптимальные условия для развития растений.",
                    "imgLink":"/landscape/sumbul1.jpg"
                },
                {
                    "name": "Установка элементов ландшафта",
                    "description":"Наши опытные специалисты выполняют высадку растений, устанавливают декоративные элементы и прорабатывают другие акценты согласно проекту.",
                    "imgLink":"/landscape/z_259ec271fb.jpg"
                },
                {
                    "name": "Строительные работы",
                    "description":"Мы осуществляем строительство элементов инфраструктуры согласно проекту, обеспечивая их прочность и функциональность.",
                    "imgLink":"/landscape/1673058345_ogorodniku-com-p-besedka-iz-shtaketnika-foto-63.jpg"
                },
                {
                    "name": "Укладка газонов и покрытий",
                    "description":"Производим укладку рулонных газонов, а также укладываем покрытия в соответствии с дизайном.",
                    "imgLink":"/landscape/Ozelenenie_uslugi_2.jpg"
                },
                {
                    "name": "Финальные штрихи",
                    "description":"Завершаем проект, добавляя последние детали, чтобы создать уютное и завершенное пространство.",
                    "imgLink":"/landscape/рпр.jpg"
                },
            ],
             "subServicePopup":
               [  {
                     "name": "Ландшафтный дизайн 'ПОД КЛЮЧ'",
                     "description": "Выполним все виды работ по благоустройству от проектирования до пуско-наладочных работ",
                     "price": "от 150 000",
                     "unit": "за 100 м²",
                     "img": {
                         "link": "/landscape/shutterstock_1564252303.jpg",
                         "alt": "",
                     }
                 },

                {
                     "name": "Проект ландшафтного дизайна",
                     "description": "Закажите проект ландшафтного дизайна с 3D визуализацией",
                     "price": "от 5 000",
                     "unit": "за 100 м²",
                     "img": {
                         "link": "/landscape/minimalizm-v-lesnoj-zone-plyuty-by-elenazhukova-landscape-studio-08.jpg",
                         "alt": "",
                     }
                 },
                {
                     "name": "Подбор и высадка растений",
                     "description": "Уже всё сделано и осталось только высадить растения? Оставьте заявку на подбор и высадку!",
                     "price": "от 10 000",
                     "unit": "за 10 шт",
                     "img": {
                         "link": "/landscape/GEO00040.jpeg",
                         "alt": "",
                     }
                 },
                 {
                     "name": "Строительство беседок и МАФов",
                     "description": "Построим беседку, разработаем и соберем мебель по индивидуальному проекту или сделаем облицовку террасы из доски",
                     "price": "от 50 000",
                     "unit": "за услугу",
                     "img": {
                         "link": "/landscape/IMG_20220814_140501_574.jpg",
                         "alt": "",
                     }
                 },
                 {
                     "name": "Мощение дорожек и тротуаров",
                     "description": "Выложим плитку или постелим асфальт предварительно подготовив основание",
                     "price": "от 500",
                     "unit": "за 1 м²",
                     "img": {
                         "link": "/landscape/863_original.jpg",
                         "alt": "",
                     }
                 },
             ]
        },
        "irrigation": {
            "img":{
                "link":"/landscape/shutterstock_679663252 (1)_lineair.png",
                "alt":"",
            },
            "title":"Системы автоматического полива",
            "description": "Система автоматического полива – это удобное и эффективное решение для поддержания здоровья вашего сада или участка. Она освобождает вас от необходимости регулировать полив вручную, автоматически подстраиваясь под потребности растений. Этот интеллектуальный подход позволяет оптимизировать использование воды, предотвращая избыточное или недостаточное поливание. Системы снабжены датчиками влажности, обеспечивая точное и эффективное увлажнение почвы. Результат – здоровые, красивые растения и заботливое отношение к окружающей среде.",
            "subServices": [
                {
                    "name": "Оценка и проектирование",
                    "description": "Мы начинаем с тщательного изучения ваших пожеланий и особенностей участка для создания персонализированного проекта.",
                    "imgLink":"/landscape/avtopoliv-gazona-5.jpg",
                },
                {
                    "name": "Выбор типа системы полива" ,
                    "description":"Мы предлагаем разнообразные типы систем полива, подбирая оптимальное решение в соответствии с конкретными требованиями вашего ландшафта.",
                    "imgLink":"/landscape/scale_1200.png",
                },
                {
                    "name":"Укладка трубопроводов" ,
                    "description":"Мы создаем систему трубопроводов, учитывая геометрию вашего участка, обеспечивая равномерное распределение влаги.",
                    "imgLink":"/landscape/instalation-1080x720.jpg",

                },
                {
                    "name":"Установка основных компонентов" ,
                    "description": "Наши специалисты заботятся о правильной установке ключевых элементов, таких как насосы, регуляторы давления и другие, обеспечивая надежную и эффективную работу системы.",
                    "imgLink":"/landscape/1676922682_bigfoto-name-p-vodoprovod-na-dache-iz-trub-pnd-70.jpg",
                },
                {
                    "name": "Установка форсунок",
                    "description": "Наши профессионалы размещают форсунки так, чтобы обеспечить равномерное покрытие всего участка водой.",
                    "imgLink":"/landscape/610a6df42308a81c0b7a7cf0-1.jpg",
                },
                {
                    "name": "Установка датчиков и контроллеров",
                    "description":"Мы интегрируем современные датчики влажности почвы и интеллектуальные контроллеры, чтобы система автоматически реагировала на изменения условий.",
                    "imgLink":"/landscape/services-6.jpg",
                },
                {
                    "name":"Подключение к источнику воды" ,
                    "description":"Мы обеспечиваем надежное подключение системы к источнику воды, предусматривая фильтрацию и обратные клапаны для безопасности и долговечности.",
                    "imgLink":"/landscape/onrxou355cm78nigp44az00fknixdxxk.jpg",
                },
                {
                    "name":"Настройка и тестирование" ,
                    "description":"Наши специалисты подробно настраивают параметры системы, гарантируя ее эффективную работу, и проводят тестирование для подтверждения надежности и производительности.",
                    "imgLink":"/landscape/maxresdefault.jpg",
                },
            ],
            "subServicePopup":
                [
                    {
                    "name": "Монтаж системы автоматического полива 'ПОД КЛЮЧ'",
                    "description": "Подготовим проект и смонтируем систему полива для вашего участка",
                    "price": "от 200 000",
                    "unit": "за услугу",
                    "img": {
                        "link": "/landscape/shutterstock_280690979.jpg",
                        "alt": "",
                        }
                    },
                    {
                        "name": "Разработка проекта системы автополива",
                        "description": "Выполним замер и проект системы автоматического полива для вашего участка",
                        "price": "от 5 000",
                        "unit": "за услугу",
                        "img": {
                            "link": "/landscape/audit-smeti-min.png",
                            "alt": "",
                        }
                    },
                    {
                        "name": "Подбор оборудования для системы автополива",
                        "description": "Подберем оборудование системы автоматического полива для вашего участка",
                        "price": "1000",
                        "unit": "за услугу",
                        "img": {
                            "link": "/landscape/610a6df42308a81c0b7a7cf0-1.jpg",
                            "alt": "",
                        }
                    },
                    {
                        "name": "Консервация системы автоматического полива на зиму",
                        "description": "Законсервируем вашу систему полива на зимний период",
                        "price": "от 3000",
                        "unit": "за услугу",
                        "img": {
                            "link": "/landscape/3c49f1_6e293ecc1cc84f089ba68816f6012fc1~mv2.jpg",
                            "alt": "",
                        }
                    },
                ]
        },
        "bath": {
            "img":{
                "link":"/landscape/shutterstock_677389261.png",
                "alt":"",
            },
            "title":"Бассейны и искусственные водоёмы",
            "description": "Бассейны и искусственные водоемы – это не просто элементы ландшафтного дизайна, а настоящие источники умиротворения и красоты. Бассейн становится местом отдыха и развлечений, в то время как искусственные водоемы добавляют природную гармонию в окружающую среду.\n" +
                "\n" +
                "Такие элементы ландшафта прекрасно сочетают красоту природы с комфортом современного образа жизни. Они придают вашему дому индивидуальность и становятся местом, где можно наслаждаться спокойствием и красотой природы прямо у себя во дворе.",
            "subServices": [
                {
                    "name": "Планирование и дизайн",
                    "description": "Определение размера, формы и стиля бассейна или водоема в соответствии с ландшафтом и предпочтениями клиента. Разработка дизайна для гармоничного вписывания в окружающую среду.",
                    "imgLink":"/landscape/9c2fbfed49c28dbb2f179a5298156442.jpg",
                },
                {
                    "name": "Выемка и выравнивание",
                    "description": "Выемка и подготовка места для бассейна или водоема. Выравнивание поверхности, учитывая геологические особенности и необходимую глубину.",
                    "imgLink":"/landscape/4d4daeb9117aa5ba6b146e1da7470b35.jpeg",
                },
                {
                    "name": "Устройство основания",
                    "description": "Создание прочного основания для бассейна с использованием качественных материалов. Обеспечение стабильности и предотвращение возможных деформаций.",
                    "imgLink":"/landscape/52a68e33fe407ad9897e3f9dd6a48be4.jpg",
                },
                {
                    "name": "Монтаж бассейна или создание формы",
                    "description": "Установка поддона для бассейна или создание формы для водоема. Обеспечение герметичности и устойчивости структуры.",
                    "imgLink":"/landscape/1a8b8d62f533a79516fbd345c9d9abc9.jpeg",
                },
                {
                    "name": "Установка системы фильтрации и очистки",
                    "description": "Монтаж системы фильтрации для поддержания чистоты воды. Установка насоса и других компонентов для обеспечения циркуляции и очистки воды.",
                    "imgLink":"/landscape/2628ec4592eef80e8fbf18b1030e7e6f.jpeg",
                },
                {
                    "name": "Завершающие отделочные работы",
                    "description": "Проведение отделочных работ, таких как облицовка бассейна, устройство бортиков, установка декоративных элементов. Создание привлекательного визуального облика.",
                    "imgLink":"/landscape/52b8dac0533032fd5e48d54dc92c85d5.jpeg",
                },
                {
                    "name": "Обучение и сдача проекта",
                    "description": "Проведение обучения заказчика управлению системой (при необходимости). Полная проверка функциональности и безопасности перед передачей объекта заказчику.",
                    "imgLink":"/landscape/12161.jpg",
                },
            ],
            "subServicePopup":
                [
                    {
                        "name": "Разработка проекта бассейна",
                        "description": "Выполним замер и разработаем проект бассейна",
                        "price": "от 15 000",
                        "unit": "за услугу",
                        "img": {
                            "link": "/landscape/shutterstock_1463170493.jpg",
                            "alt": "",
                        }
                    },
                    {
                        "name": "Строительство бассейна 'ПОД КЛЮЧ'",
                        "description": "Выполним весь спектр работ строительства бассейна на вашем участке",
                        "price": "от 190 000",
                        "unit": "за услугу",
                        "img": {
                            "link": "/landscape/tab-bath.jpg",
                            "alt": "",
                        }
                    },
                    {
                        "name": "Разработка искусственного водоёма 'ПОД КЛЮЧ'",
                        "description": "Выполним весь спектр работ строительства искусственного водоёма на вашем участке",
                        "price": "от 70 000",
                        "unit": "за услугу",
                        "img": {
                            "link": "/landscape/shutterstock_1599266917.jpg",
                            "alt": "",
                        }
                    },
                ]
        }
    }


    const { serviceName } = useParams()
    const [mainImageLink, setMainImageLink] = useState(PUBLIC_URL+ servicesDict[serviceName].img.link)


    useEffect(() => {
        setMainImageLink(PUBLIC_URL+ servicesDict[serviceName].img.link)
    }, [serviceName]);
    const servicesListPage = servicesDict[serviceName]

    return(
        <body className="service">
        <Helmet>
            <title>
                {`Закажите ${servicesDict[serviceName].title.toLowerCase()} "Под ключ" в нашей компании`}
            </title>
            <meta
                name="description"
                content={`Выполняем все этапы работ, от проектирования и технического надзора до монтажа и обслуживания. ${servicesDict[serviceName].description}`}
            />
            <meta
                name="keywords"
                content="Автополив, Ландшафтное освещение, Садовые системы полива, Смарт-технологии для сада, Садовая техника, Системы полива для участка, Ландшафтный дизайн, Садовые насосы, Экологичные решения для сада, Садовый инвентарь, Системы автоматического полива, Садовая техника и оборудование, Умные решения для сада, Садовые аксессуары, Энергосберегающие системы полива, Садовые приборы, Эстетика сада, Садовое оборудование, Эффективные решения для полива, Садовая автоматика, Садовая мебель, Садовые украшения, Газонные покрытия, Садовые лестницы, Декоративные элементы для сада, Садовые газоны, Садовые площадки, Озеленение участка, Водные фонтаны для сада, Садовые дорожки, Уход за растениями, Системы капельного полива, Садовые решетки, Садовые качели, Садовые беседки, Уличные горшки, Садовые камины, Дачные товары, Садовые строительные материалы, Садовые новинки, Садовый DIY, Садовые тенденции, Садовые акции, Садовые советы."
            />
        </Helmet>
            <section className="service-description">
                <h2 className="service-description__title">{servicesDict[serviceName].title}</h2>
                <p className="service-description__description">{servicesDict[serviceName].description}</p>
                <img className="service-description__img" src={mainImageLink}/>
            </section>
            <h3 className="main-service-section__main-title">Порядок работы</h3>
            <MainServiceSection servicesListPage={servicesListPage} serviceName={serviceName}/>
            <Advantages/>
            <SaleSection openPopup={props.openPopup}
                         setTypePopup={props.setTypePopup}/>
            <h3 className="sub-service-section__title">Выбери интересующую вас услугу</h3>
            <SubServiceSection
                openPopup={props.openPopup}
                setTypePopup={props.setTypePopup}
                servicesListPage={servicesListPage}/>

        </body>

    )
}