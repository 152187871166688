import sales_image from "./img/landscape/shutterstock_1162697149_edited.jpg";
import React from "react";
import {PUBLIC_URL} from "./constants/constants"

export default function SubServiceSection(props){
    function OpenPopup(description, linkImage, title){
        props.openPopup()
        props.setTypePopup({type:"service", title: title,  description: description, linkImage: linkImage})
    }
    return(
        <section className="sub-service-section">

            { props.servicesListPage.subServicePopup.map((item, index) => {
                return(
                    <div onClick={()=>{OpenPopup(item.description, item.img.link, item.name)}} key={index} className="sub-service-section__button">

                        <img className="sub-service-section__img" src={ PUBLIC_URL + item.img.link}   />
                        <h4 className="sub-service-section__button-title">{item.name}</h4>
                        <div  className="sub-service-section__button-title-border"></div>
                        <div className="sub-service-section__price-cont">
                            <p className="sub-service-section__price">{item.price} ₽</p>
                            <p className="sub-service-section__unit">{item.unit}</p>
                        </div>

                    </div>
                )
            })
            }
        </section>
    )}