import React, {useEffect, useState} from "react";

import SaleSlide from "./SaleSlide";
import slide from "./img/landscape/shutterstock_360058166.jpg";
import FilterBlockArticle from "./FilterBlockArticle";

export default function Articles(){


    return (

        <body className="articles">
            <div className="catalog-menu">
                <FilterBlockArticle/>




            </div>
        </body>

    )
}