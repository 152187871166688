import React from "react";
import {  useNavigate } from 'react-router-dom';

import {PUBLIC_URL} from "./constants/constants"
import logo from "./img/logo.png";
import close_icon from "./img/close.png"

export default  function BurgerMenu (props){
    const navigate = useNavigate()
    const active_page = window.location.href
    function activatedPageCategory (link){

        if (PUBLIC_URL+link === active_page){

            return ("burger-menu__nav-button_activated")
        }
            return ("")

    }
    function redirectToPage (link){
        navigate(link)
        props.closeBurger()
    }

    return(

            <div className={`burger-menu ${props.burgerOpen ? 'burger-menu_visible' : ''}`}>

                <ul className="burger-menu__menu">
                    <img className="burger-menu__close-button" src={close_icon} onClick={props.closeBurger}/>
                    <a className="logo  logo_place_burger"
                       onClick={() => redirectToPage('/')}>
                        <img className="logo__img" src={logo}/>
                        <div className="logo__description">
                            <p className="logo__title">Поливайкин</p>
                            <h1 className="logo__subtitle">Благоустройство приусадбенных участков</h1>
                        </div>
                    </a>
                    <li className={"burger-menu__nav-button" + " " +activatedPageCategory("/")}
                        onClick={() => redirectToPage('/') }>
                        <a className="burger-menu__nav-button-link"
                           >Главная</a></li>
                    <li className={"burger-menu__nav-button" + " " +activatedPageCategory("/uslugi")}
                        onClick={() => redirectToPage('/uslugi')}>
                        <a className="burger-menu__nav-button-link">Услуги</a>
                    </li>
                    <li className={"burger-menu__nav-button" + " " +activatedPageCategory("/catalog")}
                        onClick={() => redirectToPage('/market')}>
                        <a className="burger-menu__nav-button-link">Каталог</a>
                    </li>
                    <li className={"burger-menu__nav-button" + " " +activatedPageCategory("/article")}
                        onClick={() => redirectToPage('/article')}>
                        <a className="burger-menu__nav-button-link">Статьи</a>
                    </li>
                    <li className={"burger-menu__nav-button" + " " +activatedPageCategory("/contact")}
                        onClick={() => redirectToPage('/contact')}>
                        <a className="burger-menu__nav-button-link">Контакты</a>
                    </li>
                </ul>
            </div>
    )
}