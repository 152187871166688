import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import Header from "./Header"
import sortByLogo from "./img/sortby.svg"
import img from "./img/market/ip8vklpwht8pf4yp1kckgv40ns6r20wp.jpeg"
import backArrow from "./img/backarrow.svg"

import {Route, Routes, useNavigate} from "react-router-dom";
import HeaderCatalog from "./HeaderCatalog";
import FilterBlock from "./FilterBlock";
import quantityArrow from "./img/arrow-quanity.png";
import quantityAllow from "./img/output-onlinepngtools (9).png";
import quantityDisallow from "./img/output-onlinepngtools (10).png";
import img404 from "./img/1646540500_15-abrakadabra-fun-p-belii-fon-bolshoe-razreshenie-22.png";



export default function MarketCard({product}){
    const [valueProduct, setValueProduct] = useState(1)

    function upValueClickArrow(){

        setValueProduct(valueProduct+1)
    }
    function downValueClickArrow(){
        if (valueProduct>1){
            setValueProduct(valueProduct-1)
        }
    }


    function handleChangeValue(e) {
        if (e.target.value>=0) {
            setValueProduct(parseInt(e.target.value));
        }

        else {
            setValueProduct(1)
        }
    }
    function allowCartRender (){
        if(product.StockOpt || product.StockKrd1 || product.StockKrd2){
            return  (
                <div className="market-card__allow-count">
                    <p className="market-card__allow-text">
                        В наличии
                    </p>
                    <img  className="market-card__allow-image" src={quantityAllow}/>
                </div>)
        }
        else {
            return(
            <div className="market-card__allow-count">
                <p className="market-card__allow-text market-card__allow-text_disallow">
                    Под заказ
                </p>
                <img  className="market-card__allow-image" src={quantityDisallow}/>
            </div>)
        }

    }
const navigate = useNavigate()
    if(!product){
        return null
    }
    else {
        return (
            <div className="market-card">
                <div className="market-card_wrapper">
                    <a  onClick={() => navigate(`product-card/${product._id}`)}
                       className="market-card__link-count"></a>
                    <img className="market-card__img" src={product.ImageLink} onError={(e)=>{e.target.src=img404}}/>

                    <div className="market-card__price-count">
                        <p className="market-card__price">{Math.round(product.Price)} ₽</p>
                        <p className={`market-card__old-price ${product.Sale === null ? "market-card__old-price_deactivate" : ""}`}> 4 530 ₽ </p>
                    </div>

                    <h5 className="market-card__title">{product.Title}
                    </h5>


                        {allowCartRender()}

                    <div className="market-card__item-value-count">
                        <img className="market-card__quantity-arrow product-card__quantity-down" onClick={downValueClickArrow} src={quantityArrow}/>
                        <input type="number" className="market-card__item-value-input" value={valueProduct} onChange={handleChangeValue} />
                        <img className="market-card__quantity-arrow product-card__quantity-up" onClick={upValueClickArrow} src={quantityArrow}/>
                    </div>

                    <button className="market-card__order-wrap">В корзину</button>
                </div>
            </div>
        )
    }
}
