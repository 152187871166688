import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from "./Header"

import Slider from "./Slider";
import Footer from "./Footer";
import SiteSection from "./SiteSection";
import FeedbackForm from "./FeedbackForm";
import Sketh from "./Sketh";
import ArticleSliders from "./ArticleSliders";
import Advantages from "./Advantages";
import SaleSection from "./SaleSection";
import Helmet from "react-helmet";

export default function HomePage(props){


    return(
        <body className="page">
            <Helmet>
                <title>
                    Надежный подрядчик и поставщик в сфере благоустройства и ландшафтного дизайна в Краснодарском крае
                </title>
                <meta
                    name="description"
                    content="Поливайкин – ваш партнер в мире ландшафтного дизайна и умных систем полива. Обеспечьте свой сад надежными решениями автополива. Качественные товары и простота в использовании. Закажите сейчас и наслаждайтесь заботой о вашем участке без лишних усилий."
                />
                <meta
                    name="keywords"
                    content="Автополив, Ландшафтное освещение, Садовые системы полива, Смарт-технологии для сада, Садовая техника, Системы полива для участка, Ландшафтный дизайн, Садовые насосы, Экологичные решения для сада, Садовый инвентарь, Системы автоматического полива, Садовая техника и оборудование, Умные решения для сада, Садовые аксессуары, Энергосберегающие системы полива, Садовые приборы, Эстетика сада, Садовое оборудование, Эффективные решения для полива, Садовая автоматика, Садовая мебель, Садовые украшения, Газонные покрытия, Садовые лестницы, Декоративные элементы для сада, Садовые газоны, Садовые площадки, Озеленение участка, Водные фонтаны для сада, Садовые дорожки, Уход за растениями, Системы капельного полива, Садовые решетки, Садовые качели, Садовые беседки, Уличные горшки, Садовые камины, Дачные товары, Садовые строительные материалы, Садовые новинки, Садовый DIY, Садовые тенденции, Садовые акции, Садовые советы."
                />
            </Helmet>
            {/*<div className="drawing">*/}
            {/*    <canvas className="drawing__canvas" id='paper-canvas' resize='true' />*/}
            {/*<Sketh/>*/}
            {/*</div>*/}
            <Slider/>
            <h3 className={"site-section__title"}>Разделы сайта</h3>
            <SiteSection/>



            <Advantages/>

            <h3 className={"site-section__callback-title"}>Оставь заявку на <p className={"site-section__callback-subtitle"}>БЕСПЛАТНЫЙ </p>расчёт.</h3>

            <FeedbackForm/>
            <SaleSection openPopup={props.openPopup} setTypePopup={props.setTypePopup}/>

            {/*<h3 className={"article-slider__title"}>Обучающие статьи</h3>*/}
            {/*<ArticleSliders/>*/}

        </body>

    )
}
