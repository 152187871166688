import React, {useEffect, useState} from 'react';
import SliderValve from "react-slider"
import arrow from "./img/angle-right-svgrepo-com.svg"
import ReactDOM from 'react-dom/client';
import Header from "./Header"
import sortByLogo from "./img/sortby.svg"
import sortByFilter from "./img/filter.svg"
import backArrow from "./img/backarrow.svg"

import {Route, Routes} from "react-router-dom";
import HeaderCatalog from "./FilterBlock";
import {api} from "./utils/Api";



const manufacturerList = ["Rain Bird", "Hunter", "Irritrol", "Pioneer"]

export default function FilterBlock(props){
    const [maxPriceValue, setMaxPriceValue] = useState()
    const [minPriceValue, setMinPriceValue] = useState()

    const [filterCategory, setFilterCategory] = useState()
    const [priceValue, setPriceValue] = useState([minPriceValue, maxPriceValue])
    const [preFilterCount, setPreFilterCount] =useState(props.filterCount)
    const [preFilterCountLen, setPreFilterCountLen] =useState()


    function setFilterCategoryFromApi(){
        api.getCategory().then(r => setFilterCategory(r))
        api.getMaxPriceFilter({"filterDict": JSON.stringify(props.filterCount)}).then(r => setMaxPriceValue(Math.round(r['maxPrice'])))
        api.getMinPriceFilter({"filterDict": JSON.stringify(props.filterCount)}).then(r =>  setMinPriceValue(Math.round(r['minPrice'])))
    }
    function setFilter(){
        props.setFilterCount(preFilterCount)

    }

    useEffect(()=>{
        setPriceValue([minPriceValue, maxPriceValue])

    },[minPriceValue, maxPriceValue])


    useEffect(() => {
        let timeoutId;

        const sendRequest = () => {
            const Price = {
                $gte: priceValue[0],
                $lte: priceValue[1]
            }

            if (preFilterCount && Object.keys(preFilterCount).length > 0) {
                const newFilter = { ...preFilterCount, ...{ Price } };
                setPreFilterCount(newFilter);
            } else {
                setPreFilterCount({ ...{ Price } });
            }
        };

        const delayedRequest = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(sendRequest, 500);
        };

        delayedRequest();

        return () => clearTimeout(timeoutId);
    }, [priceValue]);

    useEffect(()=>{
        setFilterCategoryFromApi()
    },[])

    useEffect(() =>{
        api.getPreFilterCount({"filterDict": JSON.stringify(preFilterCount)}).then(r => setPreFilterCountLen(r["count"]) )
    }, [preFilterCount])


    return(
        <div className={`filter-block ${props.filterBlockState ? 'filter-block_visible' : ''}`}>
            <p className="filter-block__title">Системы полива</p>
            <div className="filter-block__navigate-count">
                {filterCategory ?
                    (
                        filterCategory.map((cat, index) =>{
                            return(
                                <div key={index} className="filter-block__navigate-subsection">
                                    <img className="filter-block__navigate-arrow" src={arrow}/>
                                    <p className="filter-block__navigate-text">{cat}</p>
                                </div>
                                )
                        })
                    ) : (
                    <p>Loading...</p>
                    )}
            </div>
                {(minPriceValue !== undefined && maxPriceValue !== undefined) ?
                    (<>
                        <div className="filter-block__price">
                            <p className="filter-block__title">Цена</p>
                            <SliderValve className={"filter-block__price-slider"}
                                         onChange={setPriceValue}
                                         value={priceValue}
                                         min={minPriceValue}
                                         max={maxPriceValue}/>
                        </div>
                        <div className="filter-block__price-value-count">
                            <p className="filter-block__price-value">{priceValue[0]} руб.</p>
                            <p className="filter-block__price-value">{priceValue[1]} руб.</p>
                        </div>

                    </>
                    ) : (
                        <p>Loading...</p>
                    )}
            <div className="filter-block__manufacturer">
                <p className="filter-block__title">Производитель</p>
                <div className="filter-block__manufacturer-count">
                    {manufacturerList.map((obj, index) => {
                        return (
                            <p key={index} className="filter-block__manufacturer-value">{obj}</p>
                        )
                    })}
                </div>
            </div>

            <button className="filter-block_apply-button" onClick={setFilter}>Применить</button>
            <div>
                <p className="filter-block__find-int">{`Найдено: ${preFilterCountLen}`}</p>

            </div>

        </div>

    )
}
