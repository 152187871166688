import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import Header from "./Header"
import sortByLogo from "./img/sortby.svg"
import sortByFilter from "./img/filter.svg"
import backArrow from "./img/backarrow.svg"

import {Route, Routes, useNavigate} from "react-router-dom";
import HeaderCatalog from "./HeaderCatalog";
import FilterBlock from "./FilterBlock";
import MarketCatalog from "./MarketCatalog";
import ProductCard from "./ProductCard";


const Catalog = ({ filterBlockState, setFilterBlockState, controlFilterBlockState, filterCount, setFilterCount }) => {
    return (
        <div className="catalog-system-poliv">

            <FilterBlock filterBlockState={filterBlockState}
                         setFilterBlockState={setFilterBlockState}
                         filterCount={filterCount}
                         setFilterCount={setFilterCount}/>
            <img className="header-catalog__filter-icon" onClick={controlFilterBlockState} src={sortByFilter}/>
            <MarketCatalog filterCount={filterCount}/>
        </div>
    )
}

export default function CatalogSystemPoliv(){
    const [filterBlockState, setFilterBlockState] = useState(false)
    const [underOrder,setUnderOrder]=useState({"StockOpt": true,
        "$or": [
            { "StockKrd1": true },
            { "StockKrd2": true }
        ]})

    const [filterCount, setFilterCount] = useState(underOrder)

    function controlFilterBlockState() {
        setFilterBlockState(!filterBlockState);
    }


    return(
        <div className="page catalog">
        {/*<SalesSliders/>*/}
            <Routes>
                <Route path={"/"} element={<HeaderCatalog />}/>
            </Routes>
            <Routes>

                <Route path={"/"} element={<Catalog
                    filterBlockState = {filterBlockState}
                    setFilterBlockState={setFilterBlockState}
                    controlFilterBlockState={controlFilterBlockState}
                    filterCount={filterCount}
                    setFilterCount={setFilterCount}/>}/>

                <Route path={"/product-card/:productId"} element={<ProductCard/>}/>
            </Routes>

        </div>

    )
}
