import backArrow from "./img/backarrow.svg";
import sortByLogo from "./img/sortby.svg";
import sortByFilter from "./img/filter.svg";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
export default function CatalogNavigate(props){
    const navigate = useNavigate();
    const location = useLocation();


    return(
        <div className={`catalog-navigate__navigate-menu ${props.pageModifier}`}>
            <img onClick={() => navigate(-1)} className="catalog-navigate__navigate-back-button" src={backArrow}/>
            <a className="catalog-navigate__navigate-text catalog-navigate__navigate-text_a">Каталог</a>
            <p className="catalog-navigate__navigate-text">/</p>
            <a className="catalog-navigate__navigate-text catalog-navigate__navigate-text_a">Системы полива</a>
        </div>
    )
}


