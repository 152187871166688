import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import Header from "./Header"
import sortByLogo from "./img/sortby.svg"
import img from "./img/market/ip8vklpwht8pf4yp1kckgv40ns6r20wp.jpeg"
import backArrow from "./img/backarrow.svg"
import {api} from "./utils/Api"
import {Route, Routes} from "react-router-dom";
import HeaderCatalog from "./HeaderCatalog";
import FilterBlock from "./FilterBlock";
import MarketCard from "./MarketCard";
import Helmet from "react-helmet";

export default function MarketCatalog(filterCount){

    const [catalog,setCatalog]=useState([])
    const [skipNumber, setSkipNumber] = useState(0)
    const [lengthLimit, setLengthLimit]=useState(30)

    function setCatalogDataFromApi(filter){

        api.getCatalog({ "filterDict":JSON.stringify(filter['filterCount']), "selectedFields": JSON.stringify({}),  'skipNumber':skipNumber, 'lengthLimit':lengthLimit}).then((res) => {
            setCatalog(catalog.concat(res))})
    }
    function setNewCatalogDataFromApi(filter){
        api.getCatalog({ "filterDict":JSON.stringify(filter['filterCount']), "selectedFields": JSON.stringify({}),  'skipNumber':skipNumber, 'lengthLimit':lengthLimit}).then((res) => {
            setCatalog(res)})
    }


    useEffect(()=>{
        setCatalogDataFromApi(filterCount)
    },[skipNumber])

    useEffect(()=>{
        setNewCatalogDataFromApi(filterCount)
    },[filterCount])

    return(

            <div className="market-catalog">
                <Helmet>
                    <title>
                        {`Каталог интернет-магазина систем автоматического полива - Поливайкин`}
                    </title>
                    <meta
                        name="description"
                        content={`В нашем интернет-магазине вы можете приобрести комплектующие для систем автоматического полива и другое оборудование по выгодной цене. `}
                    />
                    <meta
                        name="keywords"
                        content="Автополив, Ландшафтное освещение, Садовые системы полива, Смарт-технологии для сада, Садовая техника, Системы полива для участка, Ландшафтный дизайн, Садовые насосы, Экологичные решения для сада, Садовый инвентарь, Системы автоматического полива, Садовая техника и оборудование, Умные решения для сада, Садовые аксессуары, Энергосберегающие системы полива, Садовые приборы, Эстетика сада, Садовое оборудование, Эффективные решения для полива, Садовая автоматика, Садовая мебель, Садовые украшения, Газонные покрытия, Садовые лестницы, Декоративные элементы для сада, Садовые газоны, Садовые площадки, Озеленение участка, Водные фонтаны для сада, Садовые дорожки, Уход за растениями, Системы капельного полива, Садовые решетки, Садовые качели, Садовые беседки, Уличные горшки, Садовые камины, Дачные товары, Садовые строительные материалы, Садовые новинки, Садовый DIY, Садовые тенденции, Садовые акции, Садовые советы."
                    />
                </Helmet>
                <div className="market-catalog__count">
                {catalog!== null ? catalog.map(product=><MarketCard key={product.id} product={product}/>) : null}
                </div>
                <button  onClick={()=>{setSkipNumber(lengthLimit + skipNumber) }} className="market-catalog__more-button" >Показать ещё</button>
            </div>
    )
}
