import React from "react";
import {PUBLIC_URL} from "./constants/constants"
import {useNavigate} from "react-router-dom";

export default function Slide(props){
    const navigate = useNavigate()
    return (
            <div className="slide">
                <div className="slide__text">
                    <h3 className="slide__title">{props.obj.title}</h3>
                    <span className="slide__subtitle">{props.obj.subtitle}</span>
                    <ul className="slide__description">
                        {props.obj.description.map((lis) => (
                            <li className="slide__description_li">{lis}</li>
                        ))}

                    </ul>
                    <button onClick={() => navigate(`uslugi/${props.obj.button.link}`)} className="slide__button"> {props.obj.button.text}</button>
                </div>
                <img className="slide__image" src={PUBLIC_URL + props.obj.image.link} alt={props.obj.image.alt}/>
            </div>

    )
}